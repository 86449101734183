import { Injectable, InjectionToken, makeStateKey, TransferState, inject } from '@angular/core';

import {
  getCurrencyLocaleDataByCountryCode,
  LocaleID,
  SUPPORTED_LANGUAGES,
  User,
} from '@pedix-workspace/utils';
import {
  ApiV1_Catalog,
  ApiV1_Category,
  ApiV1_Product,
  ApiV1_ResponseBody,
} from '@pedix-workspace/api-v1';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';

const STATE_KEY_APP_REQUEST_CONTEXT = makeStateKey<AppRequestContext>('app-request-context-state');

export const APP_REQUEST_CONTEXT = new InjectionToken<AppRequestContext>('APP_REQUEST_CONTEXT');

export type AppRequestContext = {
  hostname: string;
  pathname: string;
  source: 'path' | 'subdomain' | 'domain';
  slug?: string;
  catalog?: ApiV1_Catalog;
  translations: Record<string, string | Record<string, string>>;
  localeId: LocaleID;
  selectedLanguage: SUPPORTED_LANGUAGES;
  localeData: any;
  user: User | null;
};

@Injectable({ providedIn: 'root' })
export class AppRequestContextService {
  private _appRequestContext: AppRequestContext;
  private _categories: ApiV1_Category[];
  private _products: ApiV1_Product[];

  // NOTE: this was moved from EstablishmentSharedService when removed this dependency from pedixapp app
  // This probably shouldn't be here, but for the moment it's OK
  // This is a flag only used when previewing the "client app" from the "admin app" to tweak some UI elements/stylings
  previewMode = false;

  set appRequestContext(appRequestContext: AppRequestContext) {
    this._appRequestContext = appRequestContext;

    this._categories = appRequestContext.catalog?.categories || [];
    this._products = this._categories.reduce<ApiV1_Product[]>((products, category) => {
      if (category.active && category.products && !category.isDynamic) {
        return products.concat(...category.products);
      }
      return products;
    }, []);
  }

  get appRequestContext(): AppRequestContext {
    return this._appRequestContext;
  }

  get source() {
    return this.appRequestContext.source;
  }

  get slug() {
    return this.appRequestContext.slug;
  }

  get hostname() {
    return this.appRequestContext.hostname;
  }

  get pathname() {
    return this.appRequestContext.pathname;
  }

  get catalog() {
    return this.appRequestContext.catalog;
  }

  get establishment() {
    return this.catalog?.establishment;
  }

  get subsidiaries() {
    return this.catalog?.subsidiaries;
  }

  get categories() {
    return this._categories;
  }

  get products() {
    return this._products;
  }

  get activeProducts() {
    return this._products.filter(product => product.active);
  }

  get productTags() {
    return this.catalog.productTags;
  }

  get user() {
    return this.appRequestContext.user;
  }

  get translations() {
    return this.appRequestContext.translations;
  }

  get localeId() {
    return this.appRequestContext.localeId;
  }

  get localeData() {
    return this.appRequestContext.localeData;
  }

  get selectedLanguage() {
    return this.appRequestContext.selectedLanguage;
  }

  get currencyLocaleData() {
    return getCurrencyLocaleDataByCountryCode(this.establishment?.currencyCode);
  }

  get shouldDisplaySku(): boolean {
    return (
      this.establishment?.catalogConfiguration.displaySku &&
      this.establishment?.catalogConfiguration.skuInOrderMessage
    );
  }

  private state = inject(TransferState);
  private httpClient = inject(HttpClient);

  constructor() {
    const appRequestContextData = inject(APP_REQUEST_CONTEXT, { optional: true });

    if (appRequestContextData) {
      this.appRequestContext = appRequestContextData;
      this.state.set(STATE_KEY_APP_REQUEST_CONTEXT, this.appRequestContext);
    } else {
      this.appRequestContext = this.state.get(STATE_KEY_APP_REQUEST_CONTEXT, {} as any);
    }
  }

  async refreshCatalogState() {
    const catalogApi = `${environment.apiV1Url}/establishments/${this.slug}/catalog`;
    const params = new HttpParams().appendAll({
      useSlug: 'true',
      language: this.selectedLanguage,
      skipCache: 'true',
    });

    const catalog = await this.httpClient
      .get<ApiV1_ResponseBody<ApiV1_Catalog>>(catalogApi, {
        params,
      })
      .toPromise()
      .then(response => response.data);

    this.appRequestContext = {
      ...this.appRequestContext,
      catalog,
    };
  }

  getProductsByCategoryId(categoryId: string): ApiV1_Product[] {
    return (
      this.categories
        .find(category => category.id === categoryId)
        ?.products.filter(product => product.active) || []
    );
  }
}
